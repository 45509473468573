<template>
  <div
    :class="`max-w-[274px] h-max space-y-2 ${
      forwarded
        ? `${isMyAccount?' bg-ems-main2':'bg-ems-gray600'} px-[20px] py-[20px]`
        : 'bg-none px-[0px] py-[0px]'
    } rounded-[20px] text-white overflow-hidden`"
  >
    <div v-if="forwarded" class="flex space-x-2">
      <img alt="share" src="@/static/img/icon/share.svg" />
      <p>{{ forwardFrom }}</p>
    </div>
    <div class="flex space-x-3">
      <div v-if="forwarded" class="bg-white h-[20px] w-[0.5px]"></div>
      <div>
        <div
          :class="`w-[143px] h-[35px] rounded-xl ${forwarded ?'bg-ems-tag1':'bg-ems-main2'} rounded-[29px] flex items-center px-2 justify-between`"
        >
          <div
            class="w-[24px] h-[24px] flex justify-center items-center bg-white rounded-full"
          >
            <img
              v-if="isPlayingId === mess.messageIdStr"
              alt="play"
              src="@/static/img/icon/isplay.svg"
              @click="handlePause(mess)"
            />
            <img
              v-else
              alt="play"
              src="@/static/img/icon/play-record.svg"
              @click="handlePlay(mess)"
            />
          </div>
          <img alt="play" src="@/static/img/icon/hz.svg" />
          <p
            class="text-ems-gray200 font-semibold"
            :id="`countdown${keyClass}${mess.messageIdStr}`"
          >
            {{ formatTimeAudio(mess.length) }}
          </p>
        </div>
        <audio
          v-show="false"
          ref="audioPlayer"
          :src="mess.url"
          controls
          class="w-[120px]"
        ></audio>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps,defineEmits } from 'vue';
import VueTypes from 'vue-types';
import { formatTimeAudio } from '@/util/common-utils';

const emit = defineEmits(['handlePause', 'handlePlay']);
const props = defineProps({
  forwarded: VueTypes.bool.def(false),
  forwardFrom: VueTypes.string.def(''),
  url: VueTypes.string.def(''),
  messageIdStr: VueTypes.string.def(''),
  isPlayingId: VueTypes.string.def(''),
  audioPlayer: VueTypes.string.def(''),
  mess: VueTypes.string.def(''),
  isMyAccount: VueTypes.bool.def(true),
  keyClass: VueTypes.string.def('chat'),
});
const handlePause = (mess)=>{
    emit('handlePause', mess);
}
const handlePlay = (mess)=>{
    emit('handlePlay', mess);
}
</script>

<style>

</style>
